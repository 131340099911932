import React from 'react';
import { SovAILogo } from 'assets/images';
import { FooterProps } from './types/Footer';
import { Button } from './Button';
import { Link, useNavigate } from 'react-router-dom';

const scrollToSection = (sectionId: string, offset = 0) => {
  const section = document.getElementById(sectionId);
  if (section) {
    const offsetPosition = section.offsetTop - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
};
const linkData: FooterProps[] = [
  {
    title: 'Build with us',
    links: [
      {
        name: 'GitHub',
        url: 'https://github.com/sovai-research/sovai-public',
        new_tab: true,
      },
      {
        name: 'Application',
        url: 'https://sov.ai/app',
        new_tab: true,
      },
      { name: 'API Key', url: 'https://sov.ai/subscribe', new_tab: false },
      { name: 'Documentation', url: 'https://docs.sov.ai', new_tab: true },
    ],
  },
  {
    title: 'Product',
    links: [
      { name: 'Blog', url: 'http://blog.sov.ai/', new_tab: true },
      {
        name: 'Features',
        url: '',
        new_tab: true,
        action: () => {
          scrollToSection('features');
        },
      },
      {
        name: 'Pricing',
        url: '',
        new_tab: true,
        action: () => {
          scrollToSection('pricing');
        },
      },
      { name: 'Subscribe', url: 'https://sov.ai/home', new_tab: false },
    ],
  },
];

export default function Footer() {
  return (
    <div className="flex flex-col sm:lex-row items-center justify-center bg-footer-gradient gap-y-8 pb-4 pt-12">
      <div className="gap-y-8 w-10/12 sm:w-11/12 lg:w-4/5 max-w-screen-2xl flex flex-col sm:flex-row items-start justify-center justify-between">
        <div className="sm:w-1/5 flex flex-col items-start justify-start gap-y-4">
          <div className="w-40 sm:w-32 md:w-44 xl:w-48">
            <img
              src={SovAILogo}
              alt="SovAI Logo"
              className="object-cover w-full"
            />
          </div>
          <div className="text-white text-base sm:text-sm lg:text-lg xl:text-xl">
            Python based solution for advanced investment management
          </div>
        </div>
        {linkData.map((link, index) => (
          <div
            key={index}
            className="sm:w-1/6 flex flex-col gap-y-4 lg:gap-y-4"
          >
            <div className="text-white text-base md:text-lg xl:text-xl font-semibold">
              {link.title}
            </div>
            <div className="flex flex-col text-white text-base md:text-lg xl:text-xl font-normal gap-y-2 md:gap-y-4">
              {link.links.map((linkData, index) => (
                <div
                  className="cursor-pointer"
                  key={index}
                  onClick={() => {
                    linkData.new_tab && linkData.url && linkData.url.length > 0
                      ? window.open(linkData.url, '__blank')
                      : window.open(linkData.url, '_self');
                    linkData.action && linkData.action();
                  }}
                >
                  {linkData.name}
                </div>
              ))}
            </div>
          </div>
        ))}
        <div className="w-full flex flex-row justify-center items-center sm:w-1/5 text-white text-base md:text-lg xl:text-xl font-semibold flex flex-col gap-y-4">
          <div className="text-center w-full">Get the latest updates</div>
          <div className="flex flex-col w-full items-center justify-center text-white text-xl font-normal gap-y-4">
            <div className="flex flex-row">
              <Button
                size="small"
                type="gradient"
                onClick={() => {
                  window.open('https://blog.sov.ai/', '__blank');
                }}
              >
                Blog Sign-up
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-4/5 flex flex-row items-center justify-center justify-between">
        <div className="flex flex-row w-full border-t-2 ">
          <div className="text-white pt-4">
            ©2024 Sovai, All right reserved.
          </div>
          <div className="grow"></div>
          <div className="flex flex-row gap-x-4">
            <Link to={'https://land.sov.ai/privacy-policy'}>
              <div className="text-white pt-4">Privacy Policy</div>
            </Link>
            <Link to={'https://land.sov.ai/terms-of-service'}>
              <div className="text-white pt-4">Terms & Condition</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
